/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        //== Hamurger Menu
        $('#navbarNav').on('show.bs.collapse', function () {
          $("button.hamburger").addClass( 'is-active' );
        });

        $('#navbarNav').on('hide.bs.collapse', function () {
          $("button.hamburger").removeClass( 'is-active' );
        });

        $.event.add(window, "load", this.resizeFrame);
        $.event.add(window, "resize", this.resizeFrame);

        //== Newsletter
        $(".footer-navigation .newsletter a").on('click', function(e) {
          e.preventDefault();

          var href = $(this).attr("href");
          var hash = href.substr(href.indexOf("#"));

          if ( hash ) {
            $(hash).modal('show');
          }
          
        });

        //== Headroom.js
        $("nav.navbar").headroom({
          "offset": 205,
          "tolerance": 5,
          "classes": {
            "initial": "animated",
            "pinned": "slideDown",
            "unpinned": "slideUp"
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      resizeFrame: function() {

        var scrollOffset;

        viewportHeight = $(window).height();
        viewportWidth = $(window).width();

        if ( viewportWidth <= 990 ) {
          scrollOffset = 74;
        } else {
          scrollOffset = 1;
        }

        //== ScrollTo
        $('a.scrollTo[href^="#"], a.nav-link[href^="#"], .sidebar .nav-link[href^="#"]').bind('click.smoothscroll', function(e) {
            e.preventDefault();
            var target = this.hash,
            $target = $(target);
          
            $('#navbarNav').collapse('hide');

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - scrollOffset
            }, 1500, 'easeInOutExpo', function() {

            });

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'blog': {
      init: function() {


        //== Affix
        function setAffixContainerSize(){
            $('.sidebar-inner').width($('.sidebar-inner').innerWidth());
        }

        $('.sidebar-inner').affix({
          offset: {
            top: 30,
            bottom: function () {
              return (this.bottom = $('footer.content-info').outerHeight(true) +180);
            }
          }
        }).on('affix.bs.affix',function(){
            setAffixContainerSize();
        });

        $(window).resize(function(){
            $('.sidebar-inner').width($('.sidebar-inner').parent().innerWidth()-10);
        });

        // ScrollSpy
        $('body').scrollspy({ target: '.sidebar-inner', offset: 90 });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Singler Galerie
    'single_galerie': {
      init: function() {

      },
      finalize: function() {
        // masonry on galleries
        $(window).load( function(){
          $('.masonry').masonry({
              itemSelector: '.grid-item',
              columnWidth: '.grid-sizer',
              percentPosition: true
          });
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
